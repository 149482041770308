import {
  ChangeEvent,
  Component,
  FocusEventHandler,
  Ref,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Table from "../Table/Table";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import {
  ACCOUNT_TRAN_TYPE,
  APP_ACCT_TYPES,
  APP_RESPONSES,
  NAIRA_SYMBOL,
  NON_WALLET_ACCOUNT,
  RESPONSE_CODES,
  SYMBOL_TYPES,
  TRANSACTIONS_HISTORY,
  USER_ROLES,
  WALLET_ACCOUNT,
} from "../../../../utils/constants";
import useDataInfo from "../../../../hooks/useDataInfo";
import { toast } from "react-toastify";
import { setTranQueryAccount } from "../../../../redux/features/transactionSlice";
import {
  addOneHourToDate,
  capitalize,
  decryptReduxValue,
  formatAmount,
  generateRandomNumber,
  getAllAccounts,
  successResponse,
} from "../../../../utils/functions";
import RenderAllUserAccountOptions from "../RenderAllUserAccountOptions/RenderAccountOptions";
import Crypto from "../../../../utils/crypto";
import "./styles/transactionshistory.scss";
import { useSearchParams } from "react-router-dom";
import { IUserFullTransactionHistory } from "../../../../interfaces/auth";
import PrintModal from "../PrintModal/PrintModal";
import SelectSearch, { DomProps, useSelect } from "react-select-search";
import "../../../../../node_modules/react-select-search/style.css";
import useAuth from "../../../../hooks/useAuth";
import { toastFailure } from "../../../../utils/toasts";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { format } from "date-fns";

function TransactionsHistory() {
  const [data, setData] = useState<any>({ data: [] });
  const [tranRecordToPrint, setTranRecordToPrint] =
    useState<null | IUserFullTransactionHistory>(null);
  const {
    transaction: transactionRedux,
    userAccounts,
    auth: authRedux,
  }: any = useAppSelector((state: RootState) => state);
  const userAccountsRedux = useMemo(() => userAccounts, [userAccounts]);
  const {
    getAllUserAccounts: getAllUserAccountsRedux,
    getTransactionHistory,
    getTransactionsGlobalReport,
  } = useDataInfo();
  const dispatch = useAppDispatch();
  const [printToExcel, setPrintToExcel] = useState(false);
  const sourceAccountRef = useRef<HTMLSelectElement>(null);
  const { getAccountProfile } = useAuth();
  const columns = useMemo(() => {
    const transactionHeaders = [
      {
        id: 1,
        Header: "Date",
        accessor: (row: any) => {
          const date = moment(row.tran_date).format("MMM Do YYYY, h:mm a");
          return date === "Invalid date"
            ? format(row.tran_date.split(" ")[0], "dd-MMM-yyyy")
            : format(row.tran_date, "dd-MMM-yyyy, H:mm");
        },
      },
      {
        id: 2,
        Header: "Description",
        accessor: "tran_desc",
      },
      {
        id: 3,
        Header: `Debit(${NAIRA_SYMBOL})`,
        accessor: (row: any) => {
          const { tran_type, debit_amount, credit_amount } = row;
          return tran_type === ACCOUNT_TRAN_TYPE.DEBIT
            ? formatAmount(debit_amount.toString())
            : "";
        },
      },
      {
        id: 4,
        Header: `Credit(${NAIRA_SYMBOL})`,
        accessor: (row: any) => {
          const { tran_type, debit_amount, credit_amount } = row;
          return tran_type === ACCOUNT_TRAN_TYPE.CREDIT
            ? formatAmount(credit_amount.toString())
            : "";
        },
      },
   
      {
        id: 5,
        Header: "Type",
        accessor: (row: any) => {
          return (
            <div
              className="d-flex w-100 justify-content-center justify-sm-content-start"
              style={{ height: "100%", position: "relative" }}
            >
              <span
                className={`tran-type-container rounded-pill d-flex justify-content-center align-items-center bg-${
                  row.tran_type.toUpperCase() ===
                  ACCOUNT_TRAN_TYPE.CREDIT.toUpperCase()
                    ? "light-success"
                    : "light-danger"
                }`}
              >
                <span
                  className={`tran-type-text fw-700 mod-p-[0.7rem] ${
                    row.tran_type.toUpperCase() ===
                    ACCOUNT_TRAN_TYPE.CREDIT.toUpperCase()
                      ? "tran-type-text-success"
                      : "text-danger"
                  }`}
                >
                  {row.tran_type.toUpperCase()}
                </span>
              </span>
            </div>
          );
        },
      },
      {
        id: 6,
        Header: `Balance(${NAIRA_SYMBOL})`,
        accessor: (row: any) => {
          return (
            <span className="w-100 d-flex justify-content-start">
              {formatAmount(row.balance.toString())}
            </span>
          );
        },
      },
    ];

    !printToExcel
      ? transactionHeaders.push({
          id: 7,
          Header: `Action`,
          accessor: (row: any) => {
            return (
              <div className="w-100 d-flex flex-row justify-content-center action-buttons">
                <button
                  className="bg-primary text-white mod-py-[1.5] mod-px-2 mod-border-0 mod-rounded-md"
                  onClick={() => showReceiptModal(row)}
                >
                  Preview
                </button>
              </div>
            );
          },
        })
      : null;

    return transactionHeaders;
  }, [data.data, printToExcel]);

  const getAllUserAccounts: any = () => {
    const val = Object.values(
      getAllAccounts(userAccountsRedux.accounts) as []
    ).map((account: any) => {
      const fullAccountParams: string[] = account.split("|");
      const customName = `${capitalize(fullAccountParams[3])} - ${
        fullAccountParams[0]
      } - ${NAIRA_SYMBOL}${formatAmount(fullAccountParams[2])}`;

      return {
        name: customName,
        value: fullAccountParams[0],
        key: fullAccountParams[0],
      };
    });

    return val;
  };

  const [accountHistoryDetails, setAccountHistoryDetails] = useState({
    accountType: "",
    accountNumber: "",
    balance: 0,
    customerName: "",
    statementBalance: "",
  });
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getTime() - 7 * 60 * 60 * 24 * 1000)
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [accountToQuery, setAccountToQuery] = useState({
    accountNumber: "",
  });
  const [searchParams] = useSearchParams();
  const [isDefaultPage, setIsDefaultPage] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    name: "",
    value: "",
    key: "",
  });
  const [userAccountsList, setUserAccountsList] = useState<any[]>();
  const [globalReportsData, setGlobalReportsData] = useState<any[]>([]);
  const TableRef = useRef(null);
  TableRef.current = null;

  const initStartAndEndDate = () => {
    const startDate = new Date().getTime() - 7 * 60 * 60 * 24 * 1000;
    setStartDate(() => new Date(startDate));
    setEndDate(() => new Date());
  };

  // This hook initializes userAccountsList
  useEffect(() => {
    const accNumber = searchParams.get("q");

    const allUserAccounts = getAllUserAccounts();

    let accountIndex = 0;

    const selectedAccount = allUserAccounts.find(
      (userAcc: any, index: number) => {
        accountIndex = index;
        return userAcc.value === accNumber;
      }
    );

    allUserAccounts.splice(accountIndex, 1);

    // Insert the selected account at the beginning of the array
    allUserAccounts.unshift(selectedAccount);

    setUserAccountsList(allUserAccounts);

    setAccountToQuery({ accountNumber: accNumber || "" });
  }, []);

  // This hook is called when the user selects an account from dashboard page or
  // selects an account from the source account dropdown or the account to query accountNumber is updated
  useEffect(() => {
    if (accountToQuery.accountNumber !== "") {
      queryAccount(startDate!, endDate!);
    }
  }, [accountToQuery]);

  const resetDate = () => {
    const startDate = new Date(new Date().getTime() - 7 * 60 * 60 * 24 * 1000);
    const endDate = new Date();

    // The array contains initial default values of startDate and endDate respectively
    return [startDate, endDate];
  };

  // This function ensures the value of the account selected from the dashboard page align with the
  // default value that shows up in the select account dropdown when on this transaction history page
  const setDefaultSourceAccount = (accNumber: string) => {};

  const getSourceAccount = () => {
    return accountToQuery.accountNumber;
  };

  const queryAccount = async (sD?: Date | undefined, eD?: Date | undefined) => {
    // sD and eD represents startDate and endDate
    if (sD == undefined || eD == undefined) {
      return;
    }

    toast.dismiss();

    toast.info("Fetching transactions", { isLoading: true });

    // Get the account number from the store
    const accountNumber: string = getSourceAccount();

    const accountDetails: string = decryptReduxValue(
      userAccountsRedux.accounts
    )[accountNumber];

    // console.log(accountNumber, accountDetails);

    const accountParams: any = accountDetails.split("|");

    // Show the details of the account history being fetched
    setAccountHistoryDetails({
      accountType: accountParams[1],
      accountNumber: accountParams[0],
      balance: formatAmount(accountParams[2]) as any,
      customerName: accountParams[3],
      statementBalance: accountParams[2] as any,
    });

    getTransactionHistory(
      getReqPayload(accountNumber, addOneHourToDate(sD), addOneHourToDate(eD))
    )
      .then(handleServerResponse)
      .catch(handleServerError);
  };

  const handleServerError = (err: any) => {
    toast.dismiss();
    setIsLoading(false);
  };

  const handleServerResponse = (response: any) => {
    toast.dismiss();
    setIsLoading(false);

    const { responsecode, responseCode } = response.data;

    if (successResponse([responseCode, responsecode])) {
      setData(response.data);

      return;
    } else {
      setData({ data: [] });
    }
  };

  const setQueryAccountInfo = (accountNumber: string) => {
    setAccountToQuery({ accountNumber });

    const matchedAccount = userAccountsList!.find((account: any) => {
      return account.value === accountNumber;
    });

    setSelectedAccount(matchedAccount);
  };

  const getReqPayload = (accountNumber: string, sD: Date, eD: Date) => {
    const payload = {
      startDate: (sD ? sD : startDate)!.toISOString().slice(0, 10),
      endDate: (eD ? eD : endDate)!.toISOString().slice(0, 10),
      accountNumber: accountNumber,
      withDateRange: true,
    };

    return payload;
  };

  const fetchTransactionHistory = (sD: any, eD: any) => {
    queryAccount(sD, eD);
  };

  // Called to move the date pickers
  useEffect(() => {
    if (TableRef.current === null) return;

    moveDatePickers();
  }, [TableRef.current]);

  const getDatePickersElement = () => {
    return document.querySelector("#date-pickers");
  };

  // This function moves the datepickers from the Table component into this transaction history page
  const moveDatePickers = () => {
    // Remove the child
    const datePickersElement = getDatePickersElement();
    datePickersElement?.parentNode?.removeChild(datePickersElement);

    // Insert the child
    document
      .querySelector("#dates-container")!
      .appendChild(datePickersElement!);
  };

  const showReceiptModal = (record: IUserFullTransactionHistory) => {
    setTranRecordToPrint(record);
  };

  const dismissModal = () => setTranRecordToPrint(null);

  const getStatementBalance = () => {
    return hasTransactions() && data.net_credit_debit !== 0
      ? formatAmount(data.net_credit_debit)
      : "0.00";
  };

  const hasTransactions = () => !!data.data.length;

  const printTableToExcel = (printState: boolean) => {
    setPrintToExcel((prev: any) => printState);
    return true;
  };

  const filterProfileData = (profileData: Record<string, any>) => {
    delete profileData.responsecode;
    delete profileData.responseMessage;

    return profileData;
  };

  // This hook monitors the accounts field update in redux store
  // updates userAccountsList on saving new values
  useEffect(() => {
    if (!isDefaultPage) return;

    // This ensures the updated balances reflect in the dropdown for react-select-search
    setUserAccountsList((prev) => getAllUserAccounts());

    updateAccountDetailsOnUI();
  }, [userAccountsRedux.accounts]);

  const updateAllAccounts = () => {
    getAccountProfile(decryptReduxValue(authRedux.authAccountNumber)).then(
      (retrievedProfile: any) => {
        const { responsecode, responseCode }: any = retrievedProfile.data;

        if (successResponse([responsecode, responseCode])) {
          const userProfile = filterProfileData(retrievedProfile.data);

          // Extract and save all user accounts to the redux store
          getAllUserAccountsRedux({ ...userProfile });
        } else {
          // toast.dismiss();
          // toastFailure(retrievedProfile.data.resultdescription);
          // resetLoading();
        }
      }
    );
  };

  const updateAccountDetailsOnUI = () => {
    const accountDetails: string = decryptReduxValue(
      userAccountsRedux.accounts
    )[accountToQuery.accountNumber];

    const accountParams: any = accountDetails.split("|");

    // Set the updated details of the account history being fetched
    setAccountHistoryDetails((prev) => ({
      ...prev,
      balance:
        accountParams[2] !== 0
          ? formatAmount(accountParams[2])
          : ("0.00" as any),
    }));
  };

  const getTodaysDate = () => {
    return new Date();
  };

  const refreshTranHistory = () => {
    queryAccount(startDate!, getTodaysDate());

    // Update all accounts in redux store
    updateAllAccounts();
  };

  useEffect(() => {
    if (globalReportsData.length === 0) return;

    // Click on button that triggers download into excel
    document
      .querySelector<HTMLElement>("#global-reports-table-button")!
      .click();

    setGlobalReportsData([]);
  }, [globalReportsData]);

  const printGlobalReportsToExcel = async () => {
    try {
      const response = await getTransactionsGlobalReport(
        getReqPayload(accountToQuery.accountNumber, startDate, endDate)
      );

      const { responsecode, responseCode, responseMessage, data } =
        response.data;

      if (!successResponse([responsecode, responseCode])) {
        toastFailure(responseMessage);
        return;
      }

      setGlobalReportsData(data);
    } catch (error: any) {
      toastFailure(error.response?.data?.responseMessage);
    }
  };

  const isAdmin = () =>
    decryptReduxValue(authRedux.user).role === USER_ROLES.ADMIN;

  const getBalanceForSelectedAccount = () => {
    return String(
      decryptReduxValue(userAccountsRedux.accounts)[getSourceAccount()]
    ).split("|")[2];
  };

  return (
    <div
      className="bg-white  py-4 mb-4 w-100 container-padding"
      id="recent-activity"
    >
      <div
        className="d-flex bg-green-400 rounded-md py-1 px-2 text-green-800 mx-auto row "
        style={{
          border: "1px solid rgb(22,101,52)",
          borderRadius: "0.5rem",
          width: "calc(100% - 1rem)",
        }}
      >
        {/* This section displays detailed information of the account transaction history being retrieved*/}
        {accountHistoryDetails.accountNumber && (
          <div className="tran-details-container d-flex flex-column justify-content-start align-items-start col-12 col-lg-9">
            <p
              className="text-start mb-0"
              style={{ fontSize: "1.25rem", padding: "0.2rem 0rem" }}
              id="account-details"
            >
              {accountHistoryDetails.customerName} -{" "}
              {accountHistoryDetails.accountNumber}
            </p>
            <p
              className="mb-0 w-100 text-start"
              style={{ fontSize: "1.25rem" }}
            >
              Current Balance: {NAIRA_SYMBOL}
              {formatAmount(getBalanceForSelectedAccount())}
            </p>
            {hasTransactions() && (
              <p
                className="mb-0 w-100 text-start"
                style={{ fontSize: "1.25rem" }}
              >
                {" "}
                Statement Balance: {NAIRA_SYMBOL}
                {getStatementBalance()}
              </p>
            )}
          </div>
        )}

        <div
          id="dates-container"
          className="d-flex flex-row col-12 col-lg-3 justify-content-end align-items-center w-full text-green-800"
        ></div>
      </div>

      {/* This dropdown lists all the account types of the user for which a transaction history can be fetched */}
      <div className="mb-3 pb-3 row flex-sm-column flex-md-row">
        <label
          htmlFor="account"
          className="form-label text-center mt-3 mb-2"
          style={{ fontSize: "1rem" }}
        >
          Select Account
        </label>
        <div
          className="col-12 mb-3 d-flex justify-content-start align-items-center search-dropdown"
          style={{ paddingRight: "0.5rem", paddingLeft: "0.5rem" }}
        >
          <SelectSearch
            options={userAccountsList as any}
            id={"name"}
            search={true}
            onChange={(selectedOption) => {
              setQueryAccountInfo(selectedOption.toString());
            }}
            placeholder=""
          />

          <button
            onClick={refreshTranHistory}
            className="btn btn-primary btn-sm ms-1 refresh-tran-history-btn"
            style={{
              height: "3rem",
              backgroundColor: "#2dbe60",
              fontSize: "1rem",
              padding: "1rem 2rem",
              width: "auto",
            }}
          >
            <i
              className="fas fa-sync me-2"
              style={{ height: "0.875rem", width: "0.875rem" }}
            ></i>{" "}
            Load
          </button>

          {isAdmin() && (
            <div
              id="download-global-report-container"
              className="col-md-3 ms-3 col-sm-12"
              style={{ height: "3rem" }}
            >
              <button
                className="btn btn-info text-white w-100 h-100"
                onClick={printGlobalReportsToExcel}
              >
                <i
                  className="fas fa-globe me-2"
                  style={{ height: "0.875rem", width: "0.875rem" }}
                ></i>
                Download Global Report
              </button>
            </div>
          )}
        </div>
      </div>

      <Table
        isLoading={isLoading}
        fetchTransactionHistory={fetchTransactionHistory}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        columns={columns}
        data={data.data}
        sum_credit={data.sum_credit}
        sum_debit={data.sum_debit}
        printTableToExcel={printTableToExcel}
        tableRef={TableRef}
        printGlobalReportsToExcel={printGlobalReportsToExcel}
        customerName={accountHistoryDetails.customerName}
        accountNumber={accountHistoryDetails.accountNumber}
      />

      {/* This table is used to download the global reports */}
      {globalReportsData.length > 0 && (
        <div style={{ height: "0px", overflow: "hidden" }}>
          <ReactHTMLTableToExcel
            id="global-reports-table-button"
            className="btn btn-primary"
            table="global-reports-to-xls"
            filename={`Statement-${generateRandomNumber()}`}
            sheet="tablexls"
            buttonText="Download Statement"
          />
          <table id="global-reports-to-xls">
            <thead>
              <tr>
                <th>date_time</th>
                <th>transaction_id</th>
                <th>account_number</th>
                <th>account_name</th>
                <th>amount</th>
                <th>type</th>
                <th>description</th>
              </tr>
            </thead>

            <tbody>
              {globalReportsData.map((data: any) => (
                <tr>
                  <td>{data.date_time}</td>
                  <td>{data.transaction_id}</td>
                  <td>{data.account_number}</td>
                  <td>{data.account_name}</td>
                  <td>{data.amount}</td>
                  <td>{data.type}</td>
                  <td>{data.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* End global reports */}

      {/* Modal that displays the receipt */}
      {tranRecordToPrint && (
        <PrintModal dismissModal={dismissModal} record={tranRecordToPrint} />
      )}
    </div>
  );
}

export default TransactionsHistory;
